import { React, useContext } from 'react'
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import "../styles/privacy.css"
import { HomeContext } from '../Context/HomeContext'

const Privacy = () => {

    const { selectedLanguages } = useContext(HomeContext)

    return (
        <div className="privacy-layout">
            <Navbar />
            <div className="privacy-main">

                <div className="policy-main">

                    <div className="policy-content">
                        <div className="policy-content-big-header">
                            {selectedLanguages === "English" ? "Privacy Policy" : "Gizlilik Politikası"}
                        </div>
                        <div className="policy-content-detail">
                            {selectedLanguages === "English" ?
                                "                            GET Technology Tourism Organization Travel Trade LLC As (“GET Bodrum”), we see ourselves as; We see all GET Bodrum mobile application users (“Users”) who join us as a big family. We use much more effort and precautions for the protection and privacy of our valued users' information than we do for the confidentiality and protection of our own company information."
                                :
                                "Biz, GET Teknoloji Turizm Organizasyon Seyahat Ticaret Ltd.Şti. (“GET Bodrum”) olarak, kendimizi; aramıza katılan tüm GET Bodrum mobil uygulaması kullanıcıları (“Kullanıcı”) ile birlikte büyük bir aile olarak görüyoruz. Kendi şirket bilgilerimizin gizliliği ve korunması için ortaya konulan emek ve önlemlerin çok daha fazlasını, siz değerli kullanıcılarımızın bilgilerinin korunması ve gizliliği için kullanıyoruz."
                            }
                        </div>
                        <div className="policy-content-detail">
                            {selectedLanguages === "English" ? "                            This Privacy Policy (“Policy”) aims to inform users about how GET Bodrum uses the information it collects through the mobile application, the responsibilities of users and GET Bodrum's rules regarding the protection of confidential information." : "Bu Gizlilik Politikası (“Politika”) GET Bodrum’un mobil uygulama aracılığıyla topladığı bilgileri nasıl kullandığı, kullanıcıların sorumlulukları ve GET Bodrum’un gizli bilgilerin korunmasına ilişkin kuralları hakkında kullanıcıları bilgilendirmeyi amaçlamaktadır."}
                        </div>
                    </div>

                    <div className="policy-content">
                        <div className="policy-content-header">
                            {selectedLanguages === "English" ? "1-Information We Collect" : "1-Topladığımız Bilgiler"}
                        </div>
                        <div className="policy-content-detail">
                            {selectedLanguages === "English" ? "If you use the GET Bodrum mobile application, certain information is collected to ensure that you are matched with GET Bodrum drivers and to ensure that your journey is carried out. Users provide their information to GET Bodrum when they want to use the mobile application, convey their requests, suggestions and complaints to GET Bodrum or for other reasons. In this case, certain information of the users such as name-surname, contact information, travel and payment information, request, suggestion and complaint information is collected." : "GET Bodrum mobil uygulamasını kullanmanız durumunda, GET Bodrum sürücüleri ile eşleştirilmenizi sağlamak ve yolculuğunuzun gerçekleştirilmesini temin etmek için belirli bilgileriniz toplanmaktadır. Kullanıcılar, mobil uygulamayı kullanmak, talep, öneri ve şikayetlerini GET Bodrum’a iletmek istediklerinde veya sair nedenlerle GET Bodrum’a bilgilerini sağlamaktadır. Bu durumda, kullanıcıların ad-soyad, iletişim bilgileri, yolculuk ve ödeme bilgileri, talep, öneri şikâyet bilgileri gibi belirli bilgileri toplanmaktadır."}
                        </div>
                        <div className="policy-content-detail">
                            {selectedLanguages === "English" ? "                            In the GET Bodrum mobile application, cookies are used to track users' movements, to ensure that their visits are carried out appropriately, to improve and develop the mobile application operation and content, to evaluate usage statistically and to provide users with customized services in line with their preferences and usage habits. Certain users are collected through cookies" :
                                "GET Bodrum mobil uygulamasında, kullanıcıların hareketlerini takip etmek, ziyaretlerini uygun bir şekilde gerçekleştirmelerini temin etmek, mobil uygulama işleyişini ve içeriğini iyileştirmek ve geliştirmek, kullanımları istatistiksel olarak değerlendirmek ve kullanıcılara tercih ve kullanım alışkanlıkları doğrultusunda özelleştirilmiş hizmet sunabilmek amaçlarıyla çerezler (cookies) kullanılmaktadır. Çerezler vasıtasıyla kullanıcıların belirli toplanmaktadır."}
                        </div>
                    </div>

                    <div className="policy-content">
                        <div className="policy-content-header">
                            {selectedLanguages === "English" ? "2- Purposes For Use Of Information" : "2- Bilgileri Kullanma Amaçlarımız"}
                        </div>
                        <div className="policy-content-detail">
                            {selectedLanguages === "English" ? "GET Bodrum uses the information it collects through the mobile application for the following purposes: Providing services to users through mobile application, To communicate with users, to finalize users' requests and complaints, Carrying out financial and accounting processes regarding payments, To promote our services; Carrying out advertising and campaign processes, To improve/develop our products and services based on user feedback," :
                                "GET Bodrum, mobil uygulama üzerinden topladığı bilgileri başlıca aşağıda belirtilen amaçlarla kullanılmaktadır: Mobil uygulama aracılığıyla kullanıcılara hizmet sunmak, Kullanıcılar ile iletişime geçmek, kullanıcıların talep ve şikayetlerini sonuçlandırmak,"}
                        </div>
                        <div className="policy-content-detail">
                            {selectedLanguages === "English" ? "                            To customize our services according to users' preferences, usage habits and interests, to identify and fix systemic problems in the mobile application; To carry out information security processes, measure user experiences through cookies, and improve mobile application performance. The purposes specified in this section express the general purposes for using the information collected through the mobile application." :
                                "Ödemelere ilişkin finans ve muhasebe süreçlerini yürütmek, Hizmetlerimizin tanıtımını sağlamak; reklam ve kampanya süreçlerini yürütmek, Kullanıcı geri bildirimlerine göre ürün ve hizmetlerimizi iyileştirmek/geliştirmek, Hizmetlerimizi kullanıcıların tercih, kullanım alışkanlıkları ve ilgi alanlarına göre özelleştirmek, Mobil uygulamadaki sistemsel sorunları tanımlanmak ve gidermek; bilgi güvenliği süreçlerini yürütmek, Çerezler vasıtasıyla kullanıcı deneyimlerini ölçümlemek, mobil uygulama performansını geliştirmek. Bu kısımda belirtilen amaçlar, mobil uygulama üzerinden toplanan bilgilerin genel kullanılma amaçlarını ifade etmektedir."}
                        </div>
                    </div>

                    <div className="policy-content">
                        <div className="policy-content-header">
                            {selectedLanguages === "English" ? "3- Transfer Of Your Information To Third Parties" : "3- Bilgilerinizin Üçüncü Kişilere Aktarılması"}
                        </div>
                        <div className="policy-content-detail">
                            {selectedLanguages === "English" ? "The information you provide through the mobile application may be transferred to third parties in order to achieve the above-mentioned purposes (for example, your information may need to be shared with the company providing service in this regard in order to receive technical support service for the mobile application). In this context, your information may be shared with our business partners and suppliers who receive support within the scope of product/service supply. However, when we are requested to share your information in order to fulfill our legal obligations, your information may also be shared with authorized persons, institutions and organizations." :
                                "Mobil uygulama üzerinden sağladığınız bilgiler, yukarıda belirtilen amaçların gerçekleştirilebilmesi için üçüncü kişilere aktarılabilir (örneğin mobil uygulama için teknik destek hizmetinin alınabilmesi amacıyla bilgilerinizin bu konuda hizmet veren firma ile paylaşılması gerekebilir). Bu kapsamda bilgileriniz, iş ortaklarımız ve ürün/hizmet tedariki kapsamında destek alınan tedarikçiler ile paylaşılabilecektir. Bununla birlikte, yasal yükümlülüklerimizi yerine getirebilmek için bilgilerinizi paylaşmamız talep edildiğinde, bilgileriniz yetkili kişi, kurum ve kuruluşlar ile de paylaşılabilecektir."}
                        </div>
                        <div className="policy-content-detail">
                            {selectedLanguages === "English" ? "The information provided by the users will not be transferred to third parties except for the purposes specified in the texts presented to the users and where necessary to provide the service." :
                                "Kullanıcıların, sağladığı bilgiler, kullanıcılara sunulan metinlerde belirtilen amaçlar ve hizmetin sunulabilmesi için gereken durumlar haricinde üçüncü kişilere aktarılmayacaktır."}
                        </div>
                    </div>

                    <div className="policy-content">
                        <div className="policy-content-header">
                            {selectedLanguages === "English" ? " 4- Storage And Protection Of Your Information" : "4- Bilgilerinizin Saklanması ve Korunması"}
                        </div>
                        <div className="policy-content-detail">
                            {selectedLanguages === "English" ? "Your information is stored for as long as necessary for the purpose for which we collect the information. If a specific period of time is stipulated in the legislation for us to store your information, this period is complied with." :
                                "Bilgileriniz, bilgileri toplama amacımızın gerektirdiği süre kadar saklanmaktadır. Mevzuatta bilgilerinizi saklamamız için özel bir süre öngörülüyor ise, bu süreye uygunluk sağlanmaktadır."}
                        </div>
                        <div className="policy-content-detail">
                            {selectedLanguages === "English" ? "GET Bodrum takes the necessary technical and administrative measures to protect the systems, servers and databases where your information is stored against data security threats (cyber attack, hash, etc.). Payment infrastructure is provided by MasterCard and BKM Express. GET Bodrum keeps the first six digits and last two digits of the credit card in its systems. In order to match the user's existing MasterPass and BKM Express memberships and to enable the user to use their cards as a payment tool at GET Bodrum, a membership inquiry is made in MasterPass and BKM Express with the mobile phone number registered to the application, and if the user has a membership, the user is informed and directed to add the relevant card." :
                                "GET Bodrum, bilgilerinizin muhafaza edildiği sistemleri, sunucuları ve veri tabanlarını, veri güvenliği tehditlerine (siber saldırı, hash vb.) karşı korumak için gerekli teknik ve idari tedbirleri almaktadır."}
                        </div>
                        <div className="policy-content-detail">
                            {selectedLanguages === "English" ? "The user accepts that he/she has full responsibility for the security, storage and keeping away from the information of third parties of the system access tools (user name, password, etc.) he/she uses in order to benefit from the services offered by GET Bodrum through the mobile application." :
                                "Ödeme altyapısı MasterCard ve BKM Express tarafından sağlanmaktadır. GET Bodrum kredi kartının ilk altı hanesi ve son iki hanesini sistemlerinde muhafaza etmektedir. Kullanıcının mevcut MasterPass ve BKM Express üyeliklerini eşleştirmek ve buradaki kartlarını GET Bodrum’de ödeme aracı olarak kullanabilmesini sağlamak amacıyla uygulamaya kayıt olunan cep telefonu numarasıyla MasterPass ve BKM Express’te üyelik sorgulaması yapılır ve kullanıcının üyeliği varsa, ilgili kartın eklenmesi için kullanıcıya bilgi ve yönlendirmede bulunulur. Kullanıcı, GET Bodrum tarafından mobil uygulama aracılığıyla sunulan hizmetlerden yararlanabilmek amacıyla kullandığı sistem erişim araçlarının (kullanıcı ismi, şifre vb.) güvenliği, saklanması, üçüncü kişilerin bilgisinden uzak tutulması ile ilgili tüm sorumluluğun tamamen kendisine ait olduğunu kabul etmektedir."}
                        </div>
                    </div>

                    <div className="policy-content">
                        <div className="policy-content-header">
                            {selectedLanguages === "English" ? "5- Accuracy And Update Of Your Information" : "5- Bilgilerinizin Doğruluğu ve Güncelliği"}
                        </div>
                        <div className="policy-content-detail">
                            {selectedLanguages === "English" ? "Users are responsible for the accuracy and timeliness of the information provided by users. Your information must be submitted to us truthfully and updated in case of any changes in your information. You can update the information you have shared with GET Bodrum at any time via the mobile application or by calling GET Bodrum customer services. When you provide information to GET Bodrum on behalf of someone else, you undertake that this information is correct and that you have the right/authorized to share the information with GET Bodrum." :
                                "Kullanıcıların sağladığı bilgilerin doğruluğu ve güncelliği kullanıcıların sorumluluğundadır. Bilgilerinizin gerçeğe uygun olarak tarafımıza iletilmesi ve bilgilerinizde bir değişiklik olması durumunda güncellenmesi gerekmektedir. GET Bodrum ile paylaşmış olduğunuz bilgileri, mobil uygulama üzerinden veya GET Bodrum müşteri hizmetlerini arayarak dileğiniz zaman güncelleyebilirsiniz. GET Bodrum’ye bir başkası adına bilgi sağladığınızda, bu bilgilerin doğru olduğunu, bilgileri GET Bodrum ile paylaşma hakkına sahip olduğunuzu/bu konuda yetkilendirildiğinizi taahhüt etmiş sayılırsınız."}
                        </div>
                        <div className="policy-content-detail">
                            {selectedLanguages === "English" ? "The User accepts that he/she may not be able to benefit from some services partially or completely when he/she requests that certain information not be used by GET Bodrum." :
                                "Kullanıcı, belirli bilgilerinin GET Bodrum tarafından kullanılmaması talebinde bulunduğunda, bazı hizmetlerden kısmen veya tamamen faydalanamayabileceğini kabul etmektedir."}
                        </div>
                    </div>

                    <div className="policy-content">
                        <div className="policy-content-header">
                            {selectedLanguages === "English" ? "6- Referrals To Third Parties" : "6- Üçüncü Taraflara Yapılan Yönlendirmeler"}
                        </div>
                        <div className="policy-content-detail">
                            {selectedLanguages === "English" ? "GET Bodrum mobile application may redirect to third party links that are not operated by GET Bodrum. When you visit any of the relevant links, you will be subject to the privacy policies and terms of use of the third party you visit. GET Bodrum is not responsible for the policies and practices of these third parties." :
                                "GET Bodrum mobil uygulaması, GET Bodrum tarafından işletilmeyen üçüncü taraf bağlantılara yönlendirmeler yapabilir. İlgili bağlantılardan herhangi birini ziyaretiniz sırasında, ziyaret ettiğiniz üçüncü tarafın gizlilik politikaları ve kullanım koşullarına tabi olursunuz. GET Bodrum söz konusu üçüncü tarafların politika ve uygulamalarından sorumlu değildir."}
                        </div>
                    </div>

                    <div className="policy-content">
                        <div className="policy-content-header">
                            {selectedLanguages === "English" ? " 7- Changes Made To The Mobile Application And This Policy" : "7- Mobil Uygulamada ve Bu Politikada Yapılan Değişiklikler"}
                        </div>
                        <div className="policy-content-detail">
                            {selectedLanguages === "English" ? "GET Bodrum reserves the right to change, suspend or discontinue the use of services, information, images and other elements offered through the mobile application without prior notice." :
                                "GET Bodrum, mobil uygulama aracılığıyla sunulan hizmetleri, bilgileri, görselleri ve sair unsurları önceden bildirimde bulunmaksızın değiştirme, kullanımını askıya alma veya durdurma hakkını saklı tutmaktadır."}
                        </div>
                        <div className="policy-content-detail">
                            {selectedLanguages === "English" ? "GET Bodrum reserves the right to make changes and updates on this Policy. It is the user's responsibility to follow the current version of the Policy." :
                                "GET Bodrum, bu Politika üzerinde değişiklik ve güncelleme yapma hakkını saklı tutmaktadır. Politika’nın güncel versiyonunu takip etmek, kullanıcının sorumluluğundadır."}
                        </div>
                    </div>


                    <div className="policy-content">
                        <div className="policy-content-header">
                            {selectedLanguages === "English" ? "8- Responsibility" : "8- Sorumluluk"}
                        </div>
                        <div className="policy-content-detail">
                            {selectedLanguages === "English" ? "GET Bodrum is not responsible for any direct or indirect damages and expenses that may arise as a result of any error, interruption, delay, virus, line and/or system failure that may occur during the use of the mobile application." : "Mobil uygulamanın kullanımı sırasında oluşabilecek herhangi bir hata, kesinti, gecikme, virüs, hat ve/veya sistem arızası sonucunda ortaya çıkabilecek doğrudan veya dolaylı zarar ve masraflardan GET Bodrum sorumlu değildir."}
                        </div>
                    </div>

                    <div className="policy-content">
                        <div className="policy-content-header">
                            {selectedLanguages === "English" ? "9- Intellectual Property Rights" : "9- Fikri Haklar"}
                        </div>
                        <div className="policy-content-detail">
                            {selectedLanguages === "English" ? "The copyrights of the information, materials and their arrangement in the GET Bodrum mobile application belong to GET Bodrum. All copyrights, registered trademarks, patents, intellectual and other property rights regarding the information and materials contained in the mobile application are reserved by our company." :
                                "GET Bodrum mobil uygulamada yer alan bilgi, materyal ve bunların düzenlenmesi konusundaki telif hakları, GET Bodrum’a aittir. Mobil uygulamanın içerdiği bilgi ve materyallere dair tüm telif hakları, tescilli marka, patent, entelektüel ve diğer mülkiyet hakları şirketimizde saklıdır."}
                        </div>
                        <div className="policy-content-detail">
                            {selectedLanguages === "English" ? "Any person/organization cannot use a certain part of the mobile application on another website/mobile application or establish a link to another website/mobile application to the mobile application, unless they receive our company's prior written permission." :
                                "Herhangi bir kişi/kuruluş, önceden şirketimizin yazılı iznini almadıkça, mobil uygulamanın belli bir kısmını başka bir web sitesinde/mobil uygulamada kullanamaz veya mobil uygulamaya başka bir web sitesi/mobil uygulama bağlantısını kuramaz."}
                        </div>
                    </div>

                    <div className="policy-content">
                        <div className="policy-content-header">
                            {selectedLanguages === "English" ? "10- Data Retention Policy, Managing Your Information" : "10- Veri Saklama Politikası, Bilgilerinizi Yönetme"}
                        </div>
                        <div className="policy-content-detail">
                            {selectedLanguages === "English" ? "We will retain User Provided data for as long as you use the Application and for a reasonable time thereafter. We will retain Automatically Collected information for up to one year and thereafter may store it in aggregate. If you’d like us to delete User Provided Data that you have provided via the Application, please contact us at info@getbodrum.com and we will respond in a reasonable time. Please note that some or all of the User Provided Data may be required in order for the Application to function properly." :
                             "Kullanıcı Tarafından Sağlanan verileri Uygulamayı kullandığınız sürece ve sonrasında makul bir süre boyunca saklayacağız. Otomatik Olarak Toplanan bilgileri bir yıla kadar saklayacağız ve daha sonra toplu olarak saklayabiliriz. Uygulama aracılığıyla sağladığınız Kullanıcı Tarafından Sağlanan Verileri silmemizi isterseniz, lütfen info@getbodrum.com adresinden bizimle iletişime geçin; makul bir süre içinde yanıt vereceğiz. Uygulamanın düzgün çalışması için Kullanıcı Tarafından Sağlanan Verilerin bir kısmının veya tamamının gerekli olabileceğini lütfen unutmayın."}
                        </div>
                    </div>

                    <div className="policy-content">
                        <div className="policy-content-header">
                            {selectedLanguages === "English" ? "11- Deleting Your Account" : "11- Hesabınızın Silinmesi"}
                        </div>
                        <div className="policy-content-detail">
                            {selectedLanguages === "English" ? "To delete your account via the GetBodrum app, follow these steps : Open the menu from the top right corner > Go to the Profile section > Open the menu from the top right corner in the Profile > Click the 'Delete Account' button > Request a verification code > Enter the verification code" :
                             "GetBodrum uygulaması üzerinden hesabınızı silmek için ' Sağ üst taraftan menüyü açınız > Profil sekmesine giriniz > Profilde sağ üstten menüyü açınız > Delete Account butonuna tıklayınız > Doğrulama kodunu talep ediniz > Doğrulama kodunu giriniz ' işlemlerini adım adım uygulayabilirsiniz."}
                        </div>
                    </div>


                </div>


            </div>
            <Footer />
        </div>
    )
}

export default Privacy